<template>
  <ImportTool />
</template>

<script>
import ImportTool from "./components/ImportTool.vue";

export default {
  name: "App",
  components: {
    ImportTool,
  },
};
</script>

<style>
body {
  margin: 0;
}

#app {
  align-items: center;
  background-color: lightgray;
  box-sizing: border-box;
  color: #212529;
  display: flex;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100vh;
  margin: 0;
  text-align: center;
  width: 100vw;
}
#main-panel {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 8px 19px 0px rgba(0, 0, 0, 0.31);
  margin: auto;
  padding: 25px;
  width: min(100vw, 450px);
}

.button {
  border-radius: 5px;
  border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
}
.button:disabled {
  background-color: #cccccc;
  color: #555;
  cursor: not-allowed;
  border: 0;
}
.button {
  background-color: #cf9f60;
  color: black;
  border: 0;
}
</style>
