<script>
import axios from "axios";
import { BASE_API_URL, EXPORT_URL } from "../constants";

export default {
  name: "ImportTool",
  data() {
    return {
      file: "",
      message: "Please select a file",
      disableUpload: true,
      showFileSelector: true,
      importFileName: "No File selected",
    };
  },
  methods: {
    importFile(event) {
      this.file = event.target.files[0];
      this.disableUpload = this.file == null;
      if (this.file == null) {
        this.importFileName = "No file selected";
        this.message = "Please select a file";
      } else {
        this.importFileName = this.file.name;
        this.message = "File ready to upload.";
      }
    },
    upload() {
      this.disableUpload = true;
      const formData = new FormData();
      formData.append("file", this.file);
      this.message = "Processing File";
      axios
        .post(BASE_API_URL + "/upload", formData)
        .then((response) => {
          this.message = response.data.message;
          this.clearFileInput();
          this.disableUpload = false;
        })
        .catch((error) => {
          this.message = error.message;
          this.disableUpload = false;
        });
    },
    clearFileInput() {
      this.showFileSelector = false;
      this.importFileName = "No File selected";
      this.$nextTick(() => {
        this.showFileSelector = true;
      });
    },
  },
  EXPORT_URL,
};
</script>

<template>
  <div id="main-panel">
    <div>
      <input
        type="file"
        v-if="showFileSelector"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="importFile"
        id="files"
        style="display: none"
      />
      <label for="files" class="button">Select file</label>
      <p>{{ importFileName }}</p>
    </div>
    <div>
      <button @click="upload" :disabled="disableUpload" class="button">
        Upload
      </button>
    </div>
    <h3>{{ message }}</h3>
    <div style="padding-top: 1rem">
      <div style="float: left; clear: none">
        <a href="/files/Template.xlsx" download>Download Template</a>
      </div>
      <div style="float: right; clear: none">
        <a :href="$options.EXPORT_URL" target="_blank">Processed Results</a>
      </div>
    </div>
  </div>
</template>